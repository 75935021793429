import React from "react";

import "./FeatureHoverItem.css"
import {Link} from "gatsby";

import ProgrammingIcon from "/src/assets/icons/programming.svg";
import PhoneIcon from "/src/assets/icons/phone.svg";

export default function FlutterHoverItem(props) {
  return (<li className="hoverable " key="features">
    <a href="#"
       className="relative block py-2 px-4 nav-underline">Flutter</a>
    <div className="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-l6-background">
      <div className="container mx-auto w-full flex flex-wrap justify-center mx-auto">
        <ul
          className="px-4 w-full sm:w-1/2 lg:w-1/3 border-gray-600 border-b sm:border-r lg:border-b-0 pb-6 pt-6 lg:pt-3">
          <Link className="hover:no-underline" to="/flutter-integration/">
            <div className="border-4 hover:border-l6-secondary border-l6-background rounded-md p-2">
              <div className="flex items-center">
                <ProgrammingIcon className="h-8 mb-3 mr-3"/>
                <h3 className="font-bold text-xl text-bold mb-2">Flutter Integration</h3>
              </div>
              <p className="text-sm">Read everything about how to integrate the LinkFive Flutter Plugin into your app</p>
            </div>
          </Link>

        </ul>
        <ul
          className="px-4 w-full sm:w-1/2 lg:w-1/3 border-gray-600 border-b sm:border-r-0 lg:border-b-0 pb-6 pt-6 lg:pt-3">
          <Link className="hover:no-underline" to="/flutter-in_app_purchase-plugin/">
            <div className="border-4 hover:border-l6-secondary border-l6-background rounded-md p-2">
              <div className="flex items-center">
                <PhoneIcon className="h-8 mb-3 mr-3"/>
                <h3 className="font-bold text-xl text-bold mb-2">Compare to in_app_purchase Plugin</h3>
              </div>
              <p className="text-sm">Short overview of the
                advantages of the LinkFive Flutter plugin compared to the in_app_purchase plugin.</p>
            </div>
          </Link>
        </ul>
      </div>
    </div>
  </li>)
}