import * as React from "react"

SvgWave.defaultProps = {
  backgroundcolor: "#FFF"
};

function SvgWave(props) {
  return (
    <svg
      className="wave_svg__wave-top"
      viewBox="0 0 1439 147"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M1439 70c-56.445-19.7-97.445-32.7-123-39-57.5-14.176-110.293-19.474-148-23-39.289-3.674-124.574-3.525-189 0-25.75 1.409-57.75 4.742-96 10-38.878 5.787-65.545 10.121-80 13-27.167 5.41-75.864 16.77-91 20-52.977 11.309-91.456 22.729-129 29-66.475 11.104-99.475 12.438-155 14-49.51-1.516-86.177-3.516-110-6-40.429-4.217-87.263-13.264-114-18C153.629 61.076 85.296 43.743-1 18v-32h1440v84z"
          fill={props.backgroundcolor}
        />
        <g fill="#FFF">
          <path
            d="M1439 138c-90.728-.928-147.913-27.188-291.91-59.912-95.998-21.816-251.695-29.423-467.09-22.82 289.664-73.522 542.664-70.936 759 7.759"
            opacity={0.1}
          />
          <path
            d="M1339 33.292c-177.413 32.473-326.148 52.183-446.204 59.13-120.055 6.945-264.32 3.78-432.796-9.496 47.93-1.049 168.697-19.772 362.3-56.17C1015.903-9.64 1188.137-7.462 1339 33.293z"
            opacity={0.1}
          />
          <path
            d="M393 86.348C308.17 108.67 159.917 120.392 0 97.834V18c167.828 42.056 298.828 64.84 393 68.348z"
            opacity={0.2}
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgWave
