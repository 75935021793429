import {Link} from "gatsby";
import logo from "../../assets/logo.png";

import React from "react";
import FeatureHoverItem from "./FeatureHoverItem";
import FlutterHoverItem from "./FlutterHoverItem";

export default function Header(props) {
  return (
    <nav
      id="header"
      className="fixed w-full z-30 top-0 text-white bg-l6-background"
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-start relative">
          <Link to="/">
            <img src={logo} alt="Logo" width="160px"/>
          </Link>
        </div>
        <div className="block lg:hidden pr-4">
          <button
            id="nav-toggle"
            className="flex items-center text-l5-secondary p-1 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            </svg>
          </button>
        </div>
        <div
          className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center text-l5-secondary font-bold">
            <FeatureHoverItem />
            <FlutterHoverItem />
            <li className="xl:mr-3 mr-0" key="flutter-blog">
              <Link
                to="/flutter-blog/"
                className="inline-block text-l5-secondary font-bold py-2 px-4 nav-underline"
              >
                Flutter Blog
              </Link>
            </li>
            <li className="xl:mr-3 mr-0" key="pricing">
              <Link
                to="/pricing/"
                className="inline-block text-l5-secondary font-bold py-2 px-4 nav-underline"
              >
                Pricing
              </Link>
            </li>
            <li className="xl:mr-3 mr-0" key="docs">
              <Link
                to="/docs/"
                className="inline-block text-l5-secondary font-bold py-2 px-4 nav-underline"
              >
                Docs
              </Link>
            </li>
          </ul>
          <ul>
            <li className="mr-2 ml-4" key="app">
              <Link
                to="//app.linkfive.io/login"
                className="inline-block text-l5-secondary font-bold py-2 pr-4 nav-underline"
              >
                Login
              </Link>
            </li>
          </ul>
          <a
            href={"//app.linkfive.io/sign-up" + (props !== undefined && props.utmSource !== null ? "?from=" + props.utmSource : "")}
            className="action-button focus:shadow-outline focus:outline-none"
          >
            Start for Free
          </a>
        </div>
      </div>
    </nav>
  )
}