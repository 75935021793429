import React, {useEffect} from "react"
import {useLocation} from "@reach/router" // this helps tracking the location
import {Helmet} from "react-helmet-async"

import BottomWave from "../assets/BottomWave.svg"
import TopWave from "../assets/svgr/TopWave"
import Waitlist from "../components/landing-waitlist"
import Footer from "./core/Footer";
import Header from "./core/Header";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import CookieConsent from "react-cookie-consent";
import {initializeAndTrack} from 'gatsby-plugin-gdpr-cookies'

Layout.defaultProps = {
  bottomWave: true,
  fullWave: true,
  callToAction: false,
  waveBackgroundColor: "#FFF",
  utmSource: null,
}

export default function Layout(props) {
  useEffect(() => {
    var scrollpos = window.scrollY
    var header = document.getElementById("header")

    // Change Header Color on Scroll
    document.addEventListener("scroll", function () {
      /*Apply classes for slide in bar*/
      scrollpos = window.scrollY
      if (scrollpos > 10) {
        header.classList.add("shadow")
      } else {
        header.classList.remove("shadow")
      }
    })

    // Allow for Menu Toggling on small screen
    var navMenuDiv = document.getElementById("nav-content")
    var navMenu = document.getElementById("nav-toggle")

    document.onclick = check

    function check(e) {
      var target = e && e.target

      //Nav Menu
      if (!checkParent(target, navMenuDiv)) {
        // click NOT on the menu
        if (checkParent(target, navMenu)) {
          // click on the link
          if (navMenuDiv.classList.contains("hidden")) {
            navMenuDiv.classList.remove("hidden")
            navMenu.classList.add("text-l5-primary")
          } else {
            navMenuDiv.classList.add("hidden")
            navMenu.classList.remove("text-l5-primary")
          }
        } else {
          // click both outside link and outside menu, hide menu
          navMenuDiv.classList.add("hidden")
          navMenu.classList.remove("text-l5-primary")
        }
      }
    }

    function checkParent(t, elm) {
      while (t.parentNode) {
        if (t === elm) {
          return true
        }
        t = t.parentNode
      }
      return false
    }
  }, [])

  const location = useLocation()

  return (
    <div className="leading-normal tracking-normal bg-white">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >

        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>
      <GatsbySeo titleTemplate="%s - LinkFive"/>

      {/* Navigation  */}
      <Header utmSource={props.utmSource}/>

      {/* Content */}
      {props.children}

      {/* Footer */}
      {props.bottomWave === true ? (
        <TopWave backgroundcolor={props.waveBackgroundColor}/>
      ) : (
        <></>
      )}
      {props.fullWave === true ? <BottomWave/> : <></>}
      {props.callToAction === false ? <></> : <Waitlist/>}

      <Footer/>
      <CookieConsent
        containerClasses="mx-auto"
        class="mx-auto"
        style={{
          top: "100 !important",
          left: "50%",
          margin: "5% auto",
          transform: "translate(-50%, 0)",
          marginTop: 60,
          maxWidth: 600,
          alignItems: "flex-end",
          textAlign: "justify",
          fontFamily: "\"Source Sans Pro\", sans-serif"
        }}
        location="top"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton={true}
        declineButtonClasses="text-xs"
        declineButtonStyle={{backgroundColor: "#00000000"}}
        cookieName="gatsby-gdpr-google-analytics"
        disableStyles={false}
        onAccept={() => {
          initializeAndTrack(location)
        }}
      >
        <div className="text-2xl mb-6 mt-2">This Website uses Cookies</div>
        <div className="mb-4">
          We use cookies to improve user experience and analyze website traffic. By clicking “Accept“, you agree to our
          website's cookie use as described in our <a href="/privacy" target="_blank">Cookie Policy</a>.
        </div>
        <div className="text-xl mb-2">Why?</div>
        <div className="mb-2 ">
          We want to know how you find this website. It may be through a Google search, a Reddit post or perhaps an advertisement
          we paid for. You can also send us a message using the bubble icon at the bottom right corner and quickly tell us how you like LinkFive.
          If you do not want anything, please click the ”Decline” button.
        </div>
      </CookieConsent>
    </div>
  )
}
