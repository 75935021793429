import React from "react"
import { Link } from "gatsby"
import logo from "/src/assets/logo.png"
import LinkedinIcon from "/src/assets/social/linkedin_icon.svg"
import RedditIcon from "/src/assets/social/reddit_icon.svg"

export default function Footer() {
  return (
    <footer className="bg-white pt-10">
      <div className="container mx-auto px-8">
        <div className="w-full flex flex-col md:flex-row py-6 text-gray-600">
          <div className="flex-1 mb-6 text-black">
            <Link to="/">
              <img src={logo} alt="Logo" width="160px" height="29px" />
            </Link>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Product</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="features">
                <Link
                  to="/3-biggest-problems-with-in-app-subscriptions/"
                  title="3 Biggest Problems with in-App Subscriptions and how to fix them"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  3 Biggest Problems with in-App Subscriptions
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="features">
                <Link
                  to="/product/"
                  title="Our Product"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Product
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="pricing">
                <Link
                  to="/pricing/"
                  title="Pricing"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Pricing
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="docs">
                <Link
                  to="/docs/"
                  title="Documentation"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Documentation
                </Link>
            </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="faq">
                <Link
                  to="/faq"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  FAQ
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="contact">
                <Link
                  to="/contact"
                  title="Schedule a Demo"
                  className="no-underline hover:underline hover:text-gray-500"
                  state={{ scheduleDemo: true }}
                >
                  Schedule a Demo
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Flutter</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="features">
                <Link
                  to="/flutter-integration/"
                  title="Flutter Integration"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Flutter Integration
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="pricing">
                <Link
                  to="/docs/flutter/get-started/"
                  title="Getting Started"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Getting Started
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="docs">
                <Link
                  to="/flutter-in_app_purchase-plugin/"
                  title="in_app_purchase Plugin comparison"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  in_app_purchase Plugin comparison
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Legal</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="tos">
                <Link
                  to="/tos"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Terms of Service
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="privacy">
                <Link
                  to="/privacy"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Company</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="about">
                <Link
                  to="/about"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  About us
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="contact">
                <Link
                  to="/contact"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Contact
                </Link>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="gdrive">
                <a href="https://drive.google.com/drive/folders/1_WIHwlL4nnM7yFsoSrWaDJWRm0g9xieg?usp=sharing"
                   target="_blank"
                   rel="noreferrer"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Press-Kit
                </a>
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0" key="imprint">
                <Link
                  to="/imprint"
                  className="no-underline hover:underline hover:text-gray-500"
                >
                  Legal Notice
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex-1">
            <p className="uppercase text-gray-500 md:mb-6">Follow us</p>
            <ul className="list-reset mb-6 flex">
              <li className="mt-2 inline-block mr-1 md:block" key="linkedin">
                <a
                  href="https://www.linkedin.com/company/linkfive?from=lf-website&utm_medium=socialbtn"
                  rel="nofollow"
                  title="Follow us on LinkedIn"
                >
                  <LinkedinIcon alt="LinkedIn logo" width="30px" />
                </a>
              </li>
              <li className="mt-2 inline-block mr-1 md:block" key="reddit">
                <a
                  href="https://www.reddit.com/r/LinkFive/"
                  rel="nofollow"
                  title="Join our SubReddit"
                >
                  <RedditIcon alt="Reddit logo" width="30px" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
