import React from "react"

export default function LandingWaitlist(props) {
  return (
    <section>
      <div id="waitlist" className="bg-white px-15 pt-20">
        <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center text-center justify-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center pb-10 lg:pb-0">
            <span className="block">Ready to get started?</span>
          </h2>

          <div className="pl-6">
            <a
              href="//app.linkfive.io/sign-up"
              className="action-button focus:shadow-outline focus:outline-none"
            >
              Start for Free
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
