import React from "react";

import "./FeatureHoverItem.css"
import {Link} from "gatsby";

import ProgrammingIcon from "/src/assets/icons/programming.svg";
import UsersIcon from "/src/assets/icons/users.svg";
import BookIcon from "/src/assets/icons/book.svg";
import Browser from "/src/assets/icons/browser.svg";

export default function FeatureHoverItem(props) {
  return (<li className="hoverable " key="features">
    <a href="#"
       className="relative block py-2 px-4 nav-underline">Features</a>
    <div className="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-l6-background">
      <div className="container mx-auto w-full flex flex-wrap justify-between mx-auto">
        <ul
          className="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-r lg:border-b-0 md:py-6 py-0 lg:pt-3">
          <Link className="hover:no-underline" to="/user-management/">
            <div className="border-4 hover:border-l6-secondary border-l6-background rounded-md p-2">
              <div className="flex items-center">
                <UsersIcon className="h-8 mb-3 mr-3"/>
                <h3 className="font-bold text-xl text-bold mb-2">User Management</h3>
              </div>
              <p className="text-sm">LinkFive introduced the new User Management for all our customers.</p>
            </div>
          </Link>

        </ul>
        <ul
          className="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-r-0 lg:border-r lg:border-b-0 md:py-6 py-0 lg:pt-3">
          <Link className="hover:no-underline" to="/server-api/">
            <div className="border-4 hover:border-l6-secondary border-l6-background rounded-md p-2">
              <div className="flex items-center">
                <ProgrammingIcon className="h-8 mb-3 mr-3"/>
                <h3 className="font-bold text-xl text-bold mb-2">Server Api</h3>
              </div>
              <p className="text-sm">Connect your servers to LinkFive an check if a user purchased a subscription with
                your own User Id</p>
            </div>
          </Link>
        </ul>
        <ul
          className="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-r-0 lg:border-r sm:border-b-0 md:border-b-0 md:py-6 py-0 lg:pt-3">
          <Link className="hover:no-underline" to="/showcase">
            <div className="border-4 hover:border-l6-secondary border-l6-background rounded-md p-2">
              <div className="flex items-center">
                <BookIcon className="h-8 mb-3 mr-3"/>

                <h3 className="font-bold text-xl text-bold mb-2">Showcase</h3>
              </div>
              <p className="text-sm">See all of our Showcase Apps and features that are already in production</p>
            </div>
          </Link>

        </ul>
        <ul
          className="px-4 w-full sm:w-1/2 lg:w-1/4 border-gray-600 border-b sm:border-b-0  md:border-b-0 md:py-6 py-0 lg:pt-3">
          <Link className="hover:no-underline" to="/linkfive-dashboard">
            <div className="border-4 hover:border-l6-secondary border-l6-background rounded-md p-2">
              <div className="flex items-center">
                <Browser className="h-8 mb-3 mr-3"/>

                <h3 className="font-bold text-xl text-bold mb-2">LinkFive Dashboard</h3>
              </div>
              <p className="text-sm">Look at the LinkFive dashboard of a running app.</p>
            </div>
          </Link>

        </ul>
      </div>
    </div>
  </li>)
}